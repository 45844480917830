<template>
  <div :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <div class="rows">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">{{$t('Zones Manager')}}</h3>
            </div>
            <span class="add">
              <router-link to="/admin/zones/new-zone">
                <b-button variant="warning"
                  ><i class="fas fa-plus-circle text-white mr-2" v-if="$t('clan')=='en'"></i
                  >
                  <i class="fas fa-plus-circle text-white ml-2" v-else></i
                  >{{$t('New')}}</b-button
                >
              </router-link>
            </span>
          </div>
          <div class="card-body">
            <div>
              <ZonesTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ZonesTable from "./ZonesTable";
export default {
  components: {
    ZonesTable,
  },
  mounted: function () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Zones", route: "/admin/zones" },
    ]);

    //Loads in layout to reduce the delay
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
</style>