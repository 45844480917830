<template>
  <div>
    <div>
      <b-table
        :items="table.items"
        :fields="$t('clan') == 'en' ? table.fields : tableAR.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >
        <template #empty>
          <div v-if="isBusy" class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="spinnerClass"
            ></b-spinner>
          </div>
          <p v-else class="text-center muted mb-0 mt-2" style="font-size: 13px">
            {{ $t("There are no records to show") }}
          </p>
        </template>
        <template #cell(actions)="row">
          <div class="">
            <button @click.prevent="editZone(row.item.id)" class="">
              <i class="far fas fa-eye"></i>
              {{ $t("view") }}
            </button>

            <button
              @click.prevent="
                showModal(row.item.id, row.item.name_en, row.item.name_ar)
              "
              class="ml-4"
            >
              <i class="fas fa-trash mr-2 ml-2"></i>{{ $t("Delete") }}
            </button>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul
        class="pagination"
        :style="$t('clan') == 'ar' ? 'float:right' : ''"
        dir="ltr"
      >
        <li class="page-item">
          <a class="page-link" @click="prev">{{ $t("Prev") }}</a>
        </li>
        <li class="page-item">
          <a class="page-link" @click="next">{{ $t("Next") }}</a>
        </li>
      </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title="$t('Zone Deletion')"
      hide-footer
    >
      <p v-if="$t('clan') == 'en'">
        Are you sure that you want to delete {{ tempName }}?, if you agree you
        will loose all information about Zone
      </p>
      <p v-else>
        هل أنت متأكد برغبتك بحذف منطقة ({{ tempArName }})؟, بموافقتك سوف تخسر كل
        شيئ متعلق بهذه المنطقة
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal">{{
          $t("cancel")
        }}</b-button>
        <b-button variant="danger" @click="deleteZone">{{
          $t("Delete")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DriversTable",
  data: function () {
    return {
      isBusy: "",
      zone: { id: "" },
      tempId: null,
      tempName: null,
      tempArName: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage: 1,
      lastPage: "",
    };
  },

  computed: {
    ...mapGetters({ table: "zones/table" }),
    ...mapGetters({ tableAR: "zones/tableAR" }),
  },
  async mounted() {
    this.isBusy = true;
    await this.$store.dispatch("zones/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page;
      this.isBusy = false;
    });
  },

  methods: {
    next() {
      if (this.currentPage < this.lastPage) {
        this.currentPage = this.currentPage + 1;
      }

      this.getData();
    },
    prev() {
      if (this.currentPage > this.firstPage) {
        this.currentPage = this.currentPage - 1;
      }

      this.getData();
    },
    async getData() {
      this.isBusy = true;
      await this.$store.dispatch("zones/loadPage", this.currentPage);
      this.isBusy = false;
    },
    editZone(id) {
      this.$router.push({ name: "EditZone", params: { id: id } });
    },
    async deleteZone() {
      this.zone.id = this.tempId;
      await this.$store.dispatch("zones/delete", this.zone);
      await this.$store.dispatch("zones/loadAll").then((res) => {
        this.totalRows = res.data.total;
      });
      this.hideModal();
    },

    showModal(id, name, NameAr) {
      this.$refs["my-modal"].show();
      this.tempId = id;
      this.tempName = name;
      this.tempArName = NameAr;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
</style>